/* eslint-disable @typescript-eslint/naming-convention */
export enum TelehealthResponseCodes {
  Desconocido = -3,
  Conectado_Proveedor = -2,
  Peticion_Dañada = -1,
  Ok = 0,
  Error_Interno = 1,
  Valores_Incorrectos = 2,
  Formato_Incorrecto = 3,
  Campo_Valor_No_Valido = 4,
  Comunicacion_Incorrecta = 5,
  Tamaño_Incorrecto = 6,
  Realizado_Pdt_Revision = 7,
  Finalizado = 8
}
