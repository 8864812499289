import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IRequestClientDataDialogResult } from '@app/shared/models/custom/request-client-data-dialog-result';
import { RequestMapper } from '@features/teladoc-events/mappers/request-mapper';
import { EditRequestViewModel } from '@features/teladoc-events/models/ui/edit-request-view-model';
import {
  HowdenAskForSelectService
} from '@howdeniberia/core-front';

@Component({
  selector: 'howden-request-client-data-edit-dialog',
  templateUrl: './request-client-data-edit-dialog.component.html',
  styleUrls: ['./request-client-data-edit-dialog.component.scss']
})
export class RequestClientDataEditDialogComponent implements OnInit {
  model: EditRequestViewModel = new EditRequestViewModel();

  constructor(
    public dialogRef: MatDialogRef<RequestClientDataEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: IRequestClientDataDialogResult,
    private askForSelectSrv: HowdenAskForSelectService) {
  }

  ngOnInit(): void {
    this.loadForm();
  }

  loadForm(): void {
    const obj = this.data.request;
    const isReactivation = this.data.isReactivation;
    this.model.loadFromRequestShortDto(obj, isReactivation);
  }

  public get numbersOK(): boolean {
    const land = this.model.form.get(this.model.C_LANDLINE)?.value;
    const mob = this.model.form.get(this.model.C_MOBILE)?.value;
    const res = new RequestMapper().validateContactNumbers(
      land?.toString(),
      mob?.toString()
    );
    return res;
  }

  /* #endregion */

  clean(): void {
    this.model.form.reset();
  }

  close(save: boolean): void {
    if (save) {
      this.cleanResult();
      this.data.request = new RequestMapper().editRequestViewModelToIRequestShortDto(this.model);
    }
    this.data.ok = save;
    this.dialogRef.close(this.data);
  }

  cleanResult(): void {
    if (this.model.nif !== null) {
      this.model.nif = this.model.nif.trim().replace('-', '').replace(' ', '');
    }
  }
}
