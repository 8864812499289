import { Sex, TelehealthLanguage, TimeSlot } from '@app/shared/enums';

export class RequestViewModel {
  /* #region internal properties */
  private _proyectId: number;
  private _clientId: number;
  private _name: string;
  private _surname1: string;
  private _surname2: string;
  private _nif: string;
  private _sex: Sex;
  private _birth: Date;
  private _mobilePhone: string;
  private _landLine: string;
  private _language: TelehealthLanguage;
  private _companies: string;
  private _timeSlot: TimeSlot;
  private _mailUser: string;
  private _supervisor: string;
  private _mailBO: string;
  private _observations: string;
  private _officeCode: string;
  private _officeName: string;
  private _capitals: string;
  private _formAsJSON: string;
  private _advice: boolean;
  /* #endregion */

  constructor() {
    this._proyectId = 0;
    this._clientId = 0;
    this._name = '';
    this._surname1 = '';
    this._surname2 = '';
    this._nif = '';
    this._sex = Sex.Unknow;
    this._birth = new Date();
    this._language = TelehealthLanguage.Spanish;
    this._companies = '';
    this._timeSlot = TimeSlot.Indiferent;
    this._mailUser = '';
    this._supervisor = '';
    this._mailBO = '';
    this._observations = '';
    this._officeCode = '';
    this._officeName = '';
    this._capitals = '';
    this._advice = false;
  }

  /* #region  Properties */

  /* #region Extra Properties */
  public get formAsJSON(): string {
    return this._formAsJSON;
  }

  public set formAsJSON(value: string) {
    this._formAsJSON = value;
  }
  /* #endregion */

  public get proyectId(): number {
    return this._proyectId;
  }

  public set proyectId(value: number) {
    this._proyectId = value;
  }

  public get clientId(): number {
    return this._clientId;
  }

  public set clientId(value: number) {
    this._clientId = value;
  }

  public get name(): string {
    return this._name;
  }

  public set name(value: string) {
    this._name = value;
  }

  public get surname1(): string {
    return this._surname1;
  }

  public set surname1(value: string) {
    this._surname1 = value;
  }

  public get surname2(): string {
    return this._surname2;
  }

  public set surname2(value: string) {
    this._surname2 = value;
  }

  public get nif(): string {
    return this._nif;
  }

  public set nif(value: string) {
    this._nif = value;
  }

  public get sex(): Sex {
    return this._sex;
  }

  public set sex(value: Sex) {
    this._sex = value;
  }

  public get birth(): Date {
    return this._birth;
  }

  public set birth(value: Date) {
    this._birth = value;
  }

  public get mobilePhone(): string {
    return this._mobilePhone;
  }

  public set mobilePhone(value: string) {
    this._mobilePhone = value;
  }

  public get landLine(): string {
    return this._landLine;
  }

  public set landLine(value: string) {
    this._landLine = value;
  }

  public get language(): TelehealthLanguage {
    return this._language;
  }

  public set language(value: TelehealthLanguage) {
    this._language = value;
  }

  public get companies(): string {
    return this._companies;
  }

  public set companies(value: string) {
    this._companies = value;
  }

  public get timeSlot(): TimeSlot {
    return this._timeSlot;
  }

  public set timeSlot(value: TimeSlot) {
    this._timeSlot = value;
  }

  public get mailUser(): string {
    return this._mailUser;
  }

  public set mailUser(value: string) {
    this._mailUser = value;
  }

  public get supervisor(): string {
    return this._supervisor;
  }

  public set supervisor(value: string) {
    this._supervisor = value;
  }

  public get mailBO(): string {
    return this._mailBO;
  }

  public set mailBO(value: string) {
    this._mailBO = value;
  }

  public get observations(): string {
    return this._observations;
  }

  public set observations(value: string) {
    this._observations = value;
  }

  public get officeCode(): string {
    return this._officeCode;
  }

  public set officeCode(value: string) {
    this._officeCode = value;
  }

  public get officeName(): string {
    return this._officeName;
  }

  public set officeName(value: string) {
    this._officeName = value;
  }

  public get capitals(): string {
    return this._capitals;
  }

  public set capitals(value: string) {
    this._capitals = value;
  }

  public get advice(): boolean {
    return this._advice;
  }

  public set advice(value: boolean) {
    this._advice = value;
  }

  /* #endregion */
}
