

export class CommonRegExp {
  public static NotSpace_Start_NoSpace_End = new RegExp(/^\S.*\S$/);
  public static NumericValue = new RegExp(/^\d+$/);
  public static Digit4Size = new RegExp(/^\d{4}$/);
  public static NumericValue_TwoDecimal_Dot = new RegExp(/^([0]|[1-9][0-9]*)\.?[0-9]{0,2}?$/);
  public static NumericValue_8length_TwoDecimal_Dot = new RegExp(/^([0]|[1-9][0-9]{1,8})\.?[0-9]{0,2}?$/);
  public static NumericValue_TwoDecimal_Dot_and_Comma = new RegExp(/^([0]|[1-9][0-9]*)(\.|,)?[0-9]{0,2}?$/);
  public static NumericValue_8length_TwoDecimal_Dot_and_Comma = new RegExp(/^([0]|[1-9][0-9]{1,8})(\.|,)?[0-9]{0,2}?$/);
  public static Nif_Spain = new RegExp(/^\d{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i);
  public static Cif_Spain = new RegExp(/^[ABCDEFGHJPQRSUVNW]\d{7}[a-z|0-9]$/i);
  public static Nie_Spain = new RegExp(/^[x|y|z]\d{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i);
  public static PassPort_Spain = new RegExp(/^[a-z]{3}[0-9]{6}[a-z]?$/i);
  public static IdentificationDoc_Spain = new RegExp(/(^[x|y|z]\d{7}[TRWAGMYFPDXBNJZSQVHLCKE]$)|(^[ABCDEFGHJPQRSUVNW]\d{7}[a-z|0-9]$)|(^\d{8}[TRWAGMYFPDXBNJZSQVHLCKE]$)|(^[a-z]{3}[0-9]{6}[a-z]?$)/i);
}

export function ExpressionMatches(Expression: string, value: unknown): boolean {
  const exp = new RegExp(Expression);
  try {
    return exp.test(value as string);
  } catch (ex) {
    return false;
  }
}

export function RegExpMatches(Expression: RegExp, value: unknown): boolean {
  try {
    return Expression.test(value as string);
  } catch (ex) {
    return false;
  }
}