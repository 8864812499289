<form [formGroup]="model.form">
  <div class="howden-dialog-content">
    <div mat-dialog-title class="title-text">Datos del cliente</div>
    <div class="subtitle-text">Petición manual para TELADOC</div>

    <mat-dialog-content>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--3">
          <mat-label>Nombre</mat-label>
          <mat-hint class="matHintSmall" >No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="Nombre" [formControlName]="model.C_NAME" [howdenControlError]="nameError" />
          <mat-error #nameError></mat-error>
          @if (model.form.get(model.C_NAME)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_NAME)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>Apellido 1</mat-label>
          <mat-hint class="matHintSmall">No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="Apellido 1" [formControlName]="model.C_SURNAME1" [howdenControlError]="surname1Error"/>
         <mat-error #surname1Error></mat-error>
          @if (model.form.get(model.C_SURNAME1)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_SURNAME1)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>Apellido 2</mat-label>
          <mat-hint class="matHintSmall">No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="Apellido 2" [formControlName]="model.C_SURNAME2" [howdenControlError]="surname2Error"/>
          <mat-error #surname2Error></mat-error>
          @if (model.form.get(model.C_SURNAME2)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_SURNAME2)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>NIF</mat-label>
          <mat-hint class="matHintSmall">No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="NIF" [formControlName]="model.C_NIF" [howdenControlError]="cifError"/>
          <mat-error #cifError></mat-error>
          @if (model.form.get(model.C_NIF)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--3">
          <mat-label>Fecha Nacimiento</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [howdenControlError]="fechaError"
            placeholder="Fecha Nacimiento"
            [formControlName]="model.C_BIRTH"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error #fechaError></mat-error>
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>Teléfono Fijo</mat-label>
          <input matInput placeholder="Teléfono Fijo" [formControlName]="model.C_LANDLINE" />
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>Teléfono Móvil</mat-label>
          <input matInput placeholder="Teléfono Móvil" [formControlName]="model.C_MOBILE" />
        </mat-form-field>
        <div class="f-basis--3 margin-top-20">
          @if (!numbersOK) {
            <mat-error> Uno de los teléfonos dos debe cubrirse </mat-error>
          }
        </div>
      </div>
      <div class="flex f-gap--10px">
        <howden-label-value class="sectionTitle" value="Contacto"></howden-label-value>
      </div>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--4">
          <mat-label>Mail Gestor</mat-label>
          <input matInput placeholder="Mail Gestor"
            [formControlName]="model.C_MAILUSER"
            [howdenControlError]="mailUserError"/>
          <mat-error #mailUserError></mat-error>
          @if (model.form.get(model.C_MAILUSER)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="f-basis--4">
          <mat-label>Mail Supervisor</mat-label>
          <input matInput placeholder="Mail Supervisor"
            [formControlName]="model.C_SUPERVISORMAIL"
            [howdenControlError]="mailSupervisorError"/>
          <mat-error #mailSupervisorError></mat-error>
          @if (model.form.get(model.C_SUPERVISORMAIL)?.hasError('email')) {
            <mat-error>mail no válido</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="f-basis--4">
          <mat-label>Mail BO</mat-label>
          <input matInput placeholder="Mail BO"
            [formControlName]="model.C_MAILBO"
            [howdenControlError]="mailBOError"/>
          <mat-error #mailBOError></mat-error>
          @if (model.form.get(model.C_MAILBO)?.hasError('email')) {
            <mat-error>
              mail no válido
            </mat-error>
          }
        </mat-form-field>
      </div>
      <div class="flex f-gap--10px">
        <howden-label-value class="sectionTitle" value="Información Secundaria"></howden-label-value>
      </div>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--3">
          <mat-label>Compañías</mat-label>
          <input
            matInput
            placeholder="Compañías"
            [formControlName]="model.C_COMPANIES"
            (click)="selectCompanies()"
            [howdenControlError]="companiesError"/>
          <mat-error #companiesError></mat-error>
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>Capitales</mat-label>
          <input matInput placeholder="C_LANDLINE"
            [formControlName]="model.C_CAPITALS"
            [howdenControlError]="capitalsError"/>
          <mat-error #capitalsError></mat-error>
          @if (model.form.get(model.C_CAPITALS)?.hasError('wrongValue')) {
            <mat-error>Algún capital es incorrecto</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>Cód. Oficina</mat-label>
          <input matInput placeholder="Cód. Oficina"
            [formControlName]="model.C_OFFICECODE"
            [howdenControlError]="ofiCodeError"/>
          <mat-error #ofiCodeError></mat-error>
          @if (model.form.get(model.C_OFFICECODE)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>Nombre Oficina</mat-label>
          <input matInput placeholder="Nombre Oficina"
            [formControlName]="model.C_OFFICENAME"
            [howdenControlError]="ofiNameError"/>
          <mat-error #ofiNameError></mat-error>
          @if (model.form.get(model.C_OFFICENAME)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
      </div>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--3">
          <mat-label>Idioma</mat-label>
          <mat-select
            [formControlName]="model.C_LANGUAGE"
            [howdenControlError]="languageError"
            >
            <mat-option></mat-option>
            @for (item of model.telehealthLanguages | howdenArraySort : 'value'; track item.key) {
              <mat-option [value]="item.key">
                {{ item.value }}
              </mat-option>
            }
          </mat-select>
          <mat-error #languageError></mat-error>
        </mat-form-field>

        <mat-form-field class="f-basis--3">
          <mat-label>Horario</mat-label>
          <mat-select
            [formControlName]="model.C_TIMESLOT"
            [howdenControlError]="timeSlotError"
            >
            <mat-option></mat-option>
            @for (item of model.timeSlotValues | howdenArraySort : 'value'; track item.key) {
              <mat-option [value]="item.key">
                {{ item.value }}
              </mat-option>
            }
          </mat-select>
          <mat-error #timeSlotError></mat-error>
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>Sexo</mat-label>
          <mat-select
            [formControlName]="model.C_SEX"
            [howdenControlError]="sexError"
            >
            <mat-option></mat-option>
            @for (item of model.sexValues | howdenArraySort : 'value'; track item.key) {
              <mat-option [value]="item.key">
                {{ item.value }}
              </mat-option>
            }
          </mat-select>
          <mat-error #sexError></mat-error>
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>Observaciones</mat-label>
          <input matInput placeholder="observaciones" [formControlName]="model.C_OBSERVATIONS" />
          @if (model.form.get(model.C_OBSERVATIONS)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <div class="flex f-gap--5px f-jc--end f-ai--center f-ac--center">
        <button mat-stroked-button color="warn" (click)="close(false)">
          <mat-icon class="red">cancel</mat-icon>
          Cancelar
        </button>
        <button mat-raised-button color="primary"
          [disabled]="!model.form.valid"
          (click)="close(true)">
          <mat-icon class="blue">task_alt</mat-icon>
          Aceptar
        </button>
        <button mat-raised-button (click)="clean()">
          <mat-icon>cleaning_services</mat-icon>
          <span>Limpiar</span>
        </button>
      </div>
    </mat-dialog-actions>
  </div>
</form>
