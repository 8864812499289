import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BASE_URL_ENV_CONSTANTS } from '@app/core/constants';
import { SEX_VALUES, TIME_SLOTS_VALUES } from '@app/shared/constants';
import { TELEHEALTH_LANGUAGES_VALUES } from '@app/shared/constants/telehealth-language.constants';
import { Sex, TelehealthLanguage, TimeSlot } from '@app/shared/enums';
import { IRequestContactInfoDto } from '@app/shared/models/api/teladoc/irequest-contact-info-dto';
import { EnviromentZone, GENERAL_CONSTANTS, IHowdenEnvironment } from '@howdeniberia/core-front';

@Component({
  selector: 'howden-readable-info-dialog',
  templateUrl: './readable-info-dialog.component.html',
  styleUrls: ['./readable-info-dialog.component.scss']
})
export class ReadableInfoDialogComponent implements OnInit {
  baseUrl: string;

  constructor(
    public dialogRef: MatDialogRef<ReadableInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: IRequestContactInfoDto,
    @Inject(GENERAL_CONSTANTS.EnvironmentProvideName)
    private howdenEnviroment: IHowdenEnvironment
  ) {
  }

  ngOnInit(): void {
    switch (this.howdenEnviroment.zone) {
      case EnviromentZone.LOCAL:
        this.baseUrl = BASE_URL_ENV_CONSTANTS.local ?? '';
        break;
      case EnviromentZone.DEVELOP:
        this.baseUrl = BASE_URL_ENV_CONSTANTS.develop ?? '';
        break;
      case EnviromentZone.STAGING:
        this.baseUrl = BASE_URL_ENV_CONSTANTS.staging ?? '';
        break;
      case EnviromentZone.PRODUCTION:
        this.baseUrl = BASE_URL_ENV_CONSTANTS.production ?? '';
        break;
      case EnviromentZone.DEVHOWDENGROUP:
        this.baseUrl = BASE_URL_ENV_CONSTANTS.devHowdenGroup ?? '';
        break;
      case EnviromentZone.PREHOWDENGROUP:
        this.baseUrl = BASE_URL_ENV_CONSTANTS.preHowdenGroup ?? '';
        break;
      case EnviromentZone.PROHOWDENGROUP:
        this.baseUrl = BASE_URL_ENV_CONSTANTS.proHowdenGroup ?? '';
        break;
      default:
        this.baseUrl = BASE_URL_ENV_CONSTANTS.local ?? ''; //Nunca puede ser null
        break;
    }
  }

  getLanguageDesc(langId: TelehealthLanguage): string {
    return TELEHEALTH_LANGUAGES_VALUES.find(x=> x.key === langId)?.value ?? '';
  }

  getSexDesc(sexId: Sex): string {
    return SEX_VALUES.find(x=> x.key === sexId)?.value ?? '';
  }

  getTimeSlotDesc(timeSlotId: TimeSlot | null): string {
    if (timeSlotId === null) {
      return '';
    }
    return TIME_SLOTS_VALUES.find(x=> x.key === timeSlotId)?.value ?? '';
  }

  readCompanies(): Array<string> {
    const result: Array<string> = [];
    const capitals = this.data.capitals.split(';');
    this.data.companies.split(';').forEach((value, index) => {
      result.push(`${value} (${capitals[index]})`);
    });
    return result;
  }

  readNameFromEmailInfo(value: string): string {
    if (value === '') {
      return '';
    }
    const name = value.split(' <')[0];
    return name;
  }

  readEmailFromEmailInfo(value: string): string {
    if (value === '') {
      return '';
    }
    const email = value.split('<')[1].replace('>', '');
    return email;
  }

  close(): void {
    this.dialogRef.close();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mailTo(to: string): void {
    // Empty
  }
}
