<div class="howden-dialog-content">
  <div mat-dialog-title class="margin-bottom-0">
    <div class="title-text">Datos de la petición {{ data.requestId }}</div>
    <div class="subtitle-text">Client&#64;: {{ data.client }}</div>
  </div>

  <mat-dialog-content style="overflow-y: auto !important">
    <div id="row_padre" class="flex f-fd--column f-gap--25px f-w--wrap">
      <!-- #region Client Data -->
      <div id="cl_data" class="flex f-fd--row f-gap--25px f-jc-start">
        <!-- <div> -->
        <howden-label-value
          labelText="NIF"
          value="{{ data.nif }}"
          i18n-labelText="@@app.teladoc.readable.request.nif.label"
        ></howden-label-value>
        <howden-label-value
          labelText="Sexo"
          value="{{ getSexDesc(data.sex) }}"
          i18n-labelText="@@app.teladoc.readable.request.sex.label"
        ></howden-label-value>
        <howden-label-value
          labelText="F. Nacimiento"
          value="{{ data.birth | date : 'yyyy/MM/dd' }}"
          i18n-labelText="@@app.teladoc.readable.birth.label"
        ></howden-label-value>
        <howden-label-value
          labelText="Tlf Fijo"
          value="{{ data.landLine }}"
          i18n-labelText="@@app.teladoc.readable.land.line.label"
        ></howden-label-value>
        <howden-label-value
          labelText="Tlf Móvil"
          value="{{ data.mobilePhone }}"
          i18n-labelText="@@app.teladoc.readable.mobile.phone.label"
        ></howden-label-value>
        <!-- </div> -->
      </div>
      <!-- #endregion -->

      <!-- #region Secondary -->
      <div id="second" class="flex f-fd--row f-gap--10px f-basis--auto margin-top-10">
        <!-- #region Dates -->
        <div id="dates" class="flex f-fd--column f-gap--10px f-basis--auto">
          <span><strong>Fechas</strong></span>
          <mat-divider></mat-divider>
          <div class="flex f-fd--row f-gap--25px">
            <howden-label-value
              class="f-basis--auto f-as-start"
              labelText="Registrada"
              value="{{ data.registerDate | date : 'yyyy/MM/dd' }}"
              i18n-labelText="@@app.teladoc.readable.request.register.date.label"
            ></howden-label-value>
            <howden-label-value
              class="f-basis--auto f-as-start"
              labelText="Última Interacción"
              value="{{ data.lastInteraction | date : 'yyyy/MM/dd HH:mm' }}"
              i18n-labelText="@@app.teladoc.readable.request.last.interaction.label"
            ></howden-label-value>
          </div>
        </div>
        <!-- #endregion -->

        <!-- #region Info call -->
        <div id="call" class="flex f-fd--column f-gap--10px f-basis--auto">
          <span><strong>Info llamada</strong></span>
          <mat-divider></mat-divider>
          <div class="flex f-fd--row f-gap--5px">
            <howden-label-value
              class="f-basis--auto f-as-start"
              labelText="Idioma"
              value="{{ getLanguageDesc(data.language) }}"
              i18n-labelText="@@app.teladoc.readable.request.language.label"
            ></howden-label-value>
            <howden-label-value
              class="f-basis--auto f-as-start"
              labelText="Horario"
              value="{{ getTimeSlotDesc(data.timeSlot) }}"
              i18n-labelText="@@app.teladoc.readable.request.time.slot.label"
            ></howden-label-value>
          </div>
        </div>
        <!-- #endregion -->

        <!-- #region Office -->
        <div id="offic" class="flex f-fd--column f-gap--10px f-basis--auto">
          <span><strong>Oficina</strong></span>
          <mat-divider></mat-divider>
          <div class="flex f-fd--row f-gap--5px">
            <howden-label-value
              class="f-basis--auto f-as-start"
              labelText="Código"
              value="{{ data.officeCode }}"
              i18n-labelText="@@app.teladoc.readable.request.office.code.label"
            ></howden-label-value>
            <howden-label-value
              class="f-basis--auto f-as-start"
              labelText="Nombre"
              value="{{ data.officeName }}"
              i18n-labelText="@@app.teladoc.readable.request.office.name.label"
            ></howden-label-value>
          </div>
        </div>
        <!-- #endregion -->
      </div>
      <!-- #endregion -->

      <!-- #region Contact -->
      <div id="contact" class="flex f-fd--column f-gap--10px f-basis--auto">
        <span><strong>Personal Involucrado</strong></span>
        <mat-divider></mat-divider>
        <div class="flex f-fd--row f-gap--10px">
          <span>Gest&#64;r: </span>
          @for (user of data.contactUser; track user) {
            <div>
              <a href="mailto:{{ readEmailFromEmailInfo(user) }}">{{ readNameFromEmailInfo(user) }}</a>
            </div>
          }
        </div>
        <div class="flex f-fd--row f-gap--10px">
          <span>Supervis&#64;r: </span>
          @for (supervisor of data.contacSupervisor; track supervisor) {
            <div>
              <a href="mailto:{{ readEmailFromEmailInfo(supervisor) }}">{{ readNameFromEmailInfo(supervisor) }}</a>
            </div>
          }
        </div>
        <div class="flex f-fd--row f-gap--10px">
          <span>BO: </span>
          @for (bo of data.contacBO; track bo) {
            <div>
              <a href="mailto:{{ readEmailFromEmailInfo(bo) }}">{{ readNameFromEmailInfo(bo) }}</a>
            </div>
          }
        </div>
      </div>
      <!-- #endregion -->

      <!-- #region Terciary -->
      <div id="Terciary" class="flex f-fd--row f-gap--10px f-basis--auto">
        <!-- #region Id´s -->
        <div id="ids" class="flex f-fd--column f-gap--10px f-basis--auto">
          <span><strong>Id´s</strong></span>
          <mat-divider></mat-divider>
          <div class="flex f-fd--row f-gap--10px">
            <span>Proyecto:</span>
            <a
              href="{{ this.baseUrl }}Arpa/SP/VI_ACC_IT_DEP_VE/Index/SANE/{{ data.proyectId }}"
              target="_blank"
              matTooltip="Consulta Proyecto"
              i18n-matTooltip="@@app.teladoc.readable.request.proyect.id.tooltip"
              aria-label="Consulta Proyecto"
              i18n-aria-label="@@app.teladoc.readable.request.proyect.id.tooltip"
            >
              {{ data.proyectId }}
            </a>
          </div>

          <div class="flex f-fd--row f-gap--10px">
            <span>Client&#64;:</span>
            <a
              href="{{ this.baseUrl }}GestionClientes/Clientes/ficha?CLI_Id={{ data.clientId }}"
              target="_blank"
              matTooltip="Consulta Ficha Cliente"
              i18n-matTooltip="@@app.teladoc.readable.request.client.id.tooltip"
              aria-label="Consulta Ficha Cliente"
              i18n-aria-label="@@app.teladoc.readable.request.client.id.tooltip"
            >
              {{ data.clientId }}
            </a>
          </div>
        </div>
        <!-- #endregion -->

        <!-- #region Companies -->
        <div id="company" class="flex f-fd--column f-gap--10px f-basis--auto">
          <span><strong>Compañías y Capitales</strong></span>
          <mat-divider></mat-divider>
          <div class="flex f-fd--row f-gap--10px">
            @for (capital of readCompanies(); track capital) {
              <div class="flex f-fd--row f-gap--5px">
                <howden-label-value
                  class="f-basis--auto f-as-start"
                  labelText="Compañía (Capital)"
                  value="{{ capital }}"
                  i18n-labelText="@@app.teladoc.readable.request.capital.label"
                ></howden-label-value>
              </div>
            }
          </div>
        </div>
        <!-- #endregion -->
      </div>
      <!-- #endregion -->
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="margin-top-20 flex f-jc--center f-ai--center f-gap--10px">
    <button mat-raised-button color="accent" (click)="close()">
      <mat-icon>task_alt</mat-icon>
      <span i18n="@@app.core.actions.close">Cerrar</span>
    </button>
  </mat-dialog-actions>
</div>
