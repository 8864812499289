import { DatePipe } from '@angular/common';
import { ParamMap } from '@angular/router';
import { IRegionsDto } from '@app/features/teladoc-events/models/ui/regions-dto';
import { SEX_VALUES } from '@app/shared/constants';
import { Sex } from '@app/shared/enums';
import { RequestFiltersFastOptions, RequestFiltersStatusTypes } from '@app/shared/enums/common-enum';
import { IRequestFrontFilterDto } from '@app/shared/models/api/teladoc/request-front-filter-dto';
import { IRequestNewDto } from '@app/shared/models/api/teladoc/request-new-dto';
import { IRequestShortDto } from '@app/shared/models/api/teladoc/request-short-dto';
import { RequestFilterFormViewModel } from '@features/teladoc-events/models/ui/request-filter-form-view-model';
import { IHowdenItem } from '@howdeniberia/core-front';
import { IRequestFrontFilterAppDto } from '@sharedModels/api/teladoc/request-front-filter-app-dto';
import { EditManualRequestViewModel } from '../models/ui/edit-manual-request-view-model';
import { EditRequestViewModel } from '../models/ui/edit-request-view-model';
import { RequestViewModel } from './../models/ui/request-view-model';

export class RequestMapper {
  /* #region RequestViewModel */
  public queryParamsToRequestViewModel(params: ParamMap): RequestViewModel {
    const val = new RequestViewModel();
    val.proyectId = Number(params.get('p') ?? 0);
    val.clientId = Number(params.get('c') ?? 0);
    val.companies = params.get('cs')?.trim() ?? '';
    val.name = params.get('n')?.trim() ?? '';
    val.surname1 = params.get('s1')?.trim() ?? '';
    val.surname2 = params.get('s2')?.trim() ?? '';
    val.nif = params.get('nf')?.trim() ?? '';
    val.birth = new Date(params.get('b')?.trim() ?? '');
    val.landLine = this.cleanTelephone(params.get('l') ?? '');
    val.mobilePhone = this.cleanTelephone(params.get('tb') ?? '');
    val.sex = this.getSex(params.get('s') ?? '');
    val.supervisor = params.get('ms')?.trim() ?? '';
    val.mailUser = params.get('mm')?.trim() ?? '';
    val.mailBO = params.get('mb')?.trim() ?? '';
    val.capitals = params.get('ca')?.trim() ?? '';
    val.officeCode = params.get('oc')?.trim() ?? '';
    val.officeName = params.get('on')?.trim() ?? '';
    val.advice = params.get('av') === '1' ? true : false;
    return val;
  }

  private cleanTelephone(value: string): string {
    if (value === null) {
      return '';
    }
    const res = value.split(' ').join('').split('-').join('');
    return res;
  }

  private getSex(value:string): Sex {
    if (value === '') {
      return Sex.Unknow;
    }
    return SEX_VALUES.find(x=> x.key.toString() === value)?.key as Sex;
  }

  public editManualRequestViewModelToRequestViewModel(data: EditManualRequestViewModel): RequestViewModel {
    const result: RequestViewModel = new RequestViewModel();
    result.formAsJSON = data.formVal;
    result.proyectId = data.proyectId;
    result.clientId = data.clientId;
    result.birth = data.birth;
    result.companies = data.companies;
    result.landLine = this.cleanTelephone(data.landLine);
    result.mobilePhone = this.cleanTelephone(data.mobilePhone);
    result.mailBO = data.mailBO;
    result.name = data.name;
    result.nif = data.nif;
    result.observations = data.observations;
    result.sex = data.sex;
    result.supervisor = data.supervisor;
    result.surname1 = data.surname1;
    result.surname2 = data.surname2;
    result.timeSlot = data.timeSlot;
    result.mailUser = data.mailUser;
    result.language = data.language;
    result.officeCode = data.officeCode;
    result.officeName = data.officeName;
    result.capitals = data.capitals;
    return result;
  }

  public requestViewModelToIRequestShortDto(data: RequestViewModel): IRequestShortDto {
    const obj: IRequestShortDto = <IRequestShortDto>JSON.parse(data.formAsJSON);
    return obj;
  }

  public requestViewModelToRequestNewDto(source: RequestViewModel): IRequestNewDto {
    const obj: IRequestNewDto = <IRequestNewDto>JSON.parse(source.formAsJSON);
    obj.mobilePhone = this.cleanTelephone(obj.mobilePhone);
    obj.landLine = this.cleanTelephone(obj.landLine);
    obj.sex = source.sex.toString();
    obj.timeSlot = source.timeSlot.toString();
    obj.language = source.language.toString();
    return obj;
  }
  /* #endregion */

  public editRequestViewModelToIRequestShortDto(data: EditRequestViewModel): IRequestShortDto {
    const obj: IRequestShortDto = <IRequestShortDto>JSON.parse(data.formVal);
    obj.mobilePhone = this.cleanTelephone(obj.mobilePhone);
    obj.landLine = this.cleanTelephone(obj.landLine);
    obj.sex = data.sex;
    obj.timeSlot = data.timeSlot;
    obj.language = data.language;
    return obj;
  }
  /* #endregion */

  /* #region Contact Numbers */
  public validateContactNumbers(
    landLine: string | null,
    mobilePhone: string | null
  ): boolean {
    landLine = landLine
      ? landLine
          ?.split(' ')
          .join('')
          .split('-')
          .join('')
          .split('+')
          .join('')
          .split('(')
          .join('')
          .split(')')
          .join('')
      : null;
    const landOK = Number(landLine) ? true : false;
    mobilePhone = mobilePhone
      ? mobilePhone
          ?.split(' ')
          .join('')
          .split('-')
          .join('')
          .split('+')
          .join('')
          .split('(')
          .join('')
          .split(')')
          .join('')
      : null;
    const mobOK = Number(mobilePhone) ? true : false;
    return landOK || mobOK;
  }
  /* #endregion */

  /* #region IRequestFrontFilterAppDto, RequestFilterFormViewModel */
  public iRequestFrontFilterAppDtoToRequestFilterFormViewModel(
    data: IRequestFrontFilterAppDto | undefined,
    regions: Array<IRegionsDto> | undefined
  ): RequestFilterFormViewModel {
    const res = new RequestFilterFormViewModel();
    // load Offices
    const offices: Array<IHowdenItem> = [];
    if (regions && regions.length > 0) {
      regions.forEach((reg) => {
        reg.officeLocations.forEach((office) => {
          const newOffice = {
            key: office.officeCode,
            description: `${office.officeName} (${office.officeCode})`
          } as IHowdenItem;
          offices.push(newOffice);
        });
      });
      res.officesOptions = offices;
    }
    if (data) {
      /* #region Dates */
      if (!!data.dtRegisterDateFrom || !!data.dtRegisterDateUntil) {
        res.dtFast = null;
        if (data.dtRegisterDateFrom !== '') {
          res.dtRegisterDateFrom = data.dtRegisterDateFrom;
        }
        if (data.dtRegisterDateUntil !== '') {
          res.dtRegisterDateUntil = data.dtRegisterDateUntil;
        }
      } else {
        if (data.dtFast) {
          res.dtFast = data.dtFast;
        }
      }
      /* #endregion */
      // Options
      res.stTYPES = data.stTypes;
      // Offices
      res.officeCodes = data.officeCodes;
      /* #endregion */
    }

    res.setInitialFormValue();
    return res;
  }

  public requestFilterFormViewModelToIRequestFrontFilterAppDto(
    data: RequestFilterFormViewModel
  ): IRequestFrontFilterAppDto {
    const obj: IRequestFrontFilterAppDto = <IRequestFrontFilterAppDto>(
      JSON.parse(data.formVal)
    );
    if (obj.dtFast) {
      obj.dtRegisterDateFrom = null;
      obj.dtRegisterDateUntil = null;
    } else if (obj.dtRegisterDateFrom || obj.dtRegisterDateUntil) {
      obj.dtFast = null;
    }
    return obj;
  }

  public iRequestFrontFilterAppDtoToIRequestFrontFilterDto(
    data: IRequestFrontFilterAppDto | undefined
  ): IRequestFrontFilterDto {
    const obj: IRequestFrontFilterDto = {} as IRequestFrontFilterDto;
    if (!data) {
      return obj;
    }
    // Dates
    if (data.dtRegisterDateFrom || data.dtRegisterDateUntil) {
      obj.dtRegisterDateFrom = data.dtRegisterDateFrom
        ? data.dtRegisterDateFrom
        : null;
      obj.dtRegisterDateUntil = data.dtRegisterDateUntil
        ? data.dtRegisterDateUntil
        : null;
    } else if (data.dtFast && data.dtFast !== RequestFiltersFastOptions.Ninguno) {
      const res = this.calculateDatesWithFastOption(data.dtFast);
      obj.dtRegisterDateFrom = res[0];
      obj.dtRegisterDateUntil = res[1];
    }
    // Options
    if (data.stTypes) {
      obj.stDeleted = data.stTypes.findIndex((x) => x === RequestFiltersStatusTypes.Borradas) > -1
          ? true
          : null;
      obj.stFinished = data.stTypes.findIndex((x) => x === RequestFiltersStatusTypes.Completadas) > -1
          ? true
          : null;
      obj.stInProgress = data.stTypes.findIndex((x) => x === RequestFiltersStatusTypes.En_Circuito) > -1
          ? true
          : null;
      obj.stWithIncidence = data.stTypes.findIndex((x) => x === RequestFiltersStatusTypes.Con_Incidencia) > -1
          ? true
          : null;
    }
    obj.officeCodes = data.officeCodes?.length === 0 ? [] : data.officeCodes;
    return obj;
  }

  private calculateDatesWithFastOption(option: number | null): [string | null, string | null] {
    const today: Date = new Date();
    let dateFrom: Date | null = null;
    let dateUntil: Date | null = null;
    const actualTr = Math.floor(today.getMonth() / 3) + 1;
    let actualTriEnd: number | null = null;
    let actualTriInit: number | null = null;
    let year: number | null = null;
    const pipe: DatePipe = new DatePipe('es-ES');
    switch (option) {
      case RequestFiltersFastOptions.Mes_Actual:
        dateFrom = new Date(today.getFullYear(), today.getMonth(), 1);
        dateUntil = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      case RequestFiltersFastOptions.Mes_Pasado:
        dateFrom = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        dateUntil = new Date(today.getFullYear(), today.getMonth(), 0);
        break;
      case RequestFiltersFastOptions.Trimestre_Actual:
        actualTriEnd = actualTr * 3;
        actualTriInit = actualTriEnd - 3;
        dateFrom = new Date(today.getFullYear(), actualTriInit, 1);
        dateUntil = new Date(today.getFullYear(), actualTriEnd, 0);
        break;
      case RequestFiltersFastOptions.Trimestre_Pasado:
        year = today.getFullYear() + (actualTr === 1 ? 1 : 0);
        actualTriEnd = (actualTr === 1 ? 4 : actualTr - 1) * 3;
        actualTriInit = actualTriEnd - 3;
        dateFrom = new Date(year, actualTriInit, 1);
        dateUntil = new Date(year, actualTriEnd, 0);
        break;
    }
    const from = pipe.transform(dateFrom, 'yyyy-MM-ddT00:00:00.000');
    const until = pipe.transform(dateUntil, 'yyyy-MM-ddT23:59:59.999');
    return [from ? from : null, until ? until : null];
  }
  /* #endregion */
}
