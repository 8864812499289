import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { REQUESTS_LIST_COLUMNS } from '@app/shared/constants';
import { REQUEST_EVENTS_LIST_COLUMNS } from '@app/shared/constants/request-events-list-columns';
import { ResultsCodeSubCode, State } from '@app/shared/enums';
import { IRequestClientDataDialogResult } from '@app/shared/models/custom/request-client-data-dialog-result';
import { TeladocService } from '@app/shared/services/api/teladoc.service';
import {
  DialogResultTypes,
  HowdenAlertService,
  HowdenAuthService,
  HowdenLoaderService,
  IDialogResult,
  YesNoService
} from '@howdeniberia/core-front';
import { TelesuscripcionAccessRoles } from '@shared/models/api/teladoc/telesuscripcion-access-roles';
import { TableBaseComponent } from '@sharedComponents/table-base/table-base.component';
import { IRequestEventDto } from '@sharedModels/api/teladoc/request-event-dto';
import { IRequestShortDto } from '@sharedModels/api/teladoc/request-short-dto';
import { ReadableInfoDialogComponent } from '../readable-info-dialog/readable-info-dialog.component';
import { RequestClientDataEditDialogComponent } from '../request-client-data-edit-dialog/request-client-data-edit-dialog.component';
import { RequestEventsListComponent } from '../request-events-list/request-events-list.component';

@Component({
  selector: 'howden-requests-list',
  templateUrl: './requests-list.component.html',
  styleUrls: ['./requests-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class RequestsListComponent extends TableBaseComponent implements OnInit {
  readonly defaultPagination = 10;

  @Input() set data(value: unknown[]) {
    this._data = value;
    super.loadData(this._data, this.defaultPagination);
    this.dataSource.filterPredicate = (data: IRequestShortDto, filter: string) => {
      if (!isNaN(Number(filter))) {
        const num = Number(filter);
        return data.requestId === num;
      } else if (data.client.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) > -1
            || data.nif.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) > -1) {
        return true;
      }
      return false;
    };
  }

  get data(): unknown[] {
    // eslint-disable-next-line keyword-spacing
    return <Array<unknown>>this._data;
  }

  override dataSource: MatTableDataSource<IRequestShortDto>;
  override dataSourceSecondary: MatTableDataSource<IRequestEventDto>;

  override expandedElement: IRequestShortDto | null = null;
  override colsView = REQUESTS_LIST_COLUMNS;
  @ViewChild(RequestEventsListComponent)
  eventsTable!: RequestEventsListComponent;
  override colsDetailView = REQUEST_EVENTS_LIST_COLUMNS;

  private originalRow: IRequestShortDto;

  seeManual = false;
  isBO = false;

  constructor(
    protected override loaderSrv: HowdenLoaderService,
    private readonly alertSrv: HowdenAlertService,
    private readonly actionSrv: YesNoService,
    private teladocSrv: TeladocService,
    private dialog: MatDialog,
    private authSrv: HowdenAuthService
  ) {
    super(loaderSrv);
  }

  override ngOnInit(): void {
    // code
    this.seeManual = this.authSrv.isUserAuthorized([
      TelesuscripcionAccessRoles.TELESUSCRIPCIONTECNIC,
    ]);
    this.isBO = this.authSrv.userHasRol(
      TelesuscripcionAccessRoles.TELESUSCRIPCIONBO
    );
  }

  /* #region click */
  override eventClick(row: IRequestShortDto) {
    row = this.translateFirstEventMessage(row);
    this.expandedElement = !this.expandedElement
      ? row
      : this.expandedElement === row
      ? null
      : row;
    this.dataSourceSecondary = new MatTableDataSource<IRequestEventDto>(
      this.expandedElement?.events
    );
  }

  private translateFirstEventMessage(row: IRequestShortDto): IRequestShortDto {
    //TODO: check this
    if (!row?.events || row.events.length === 0) {
      return row;
    }
    const index = row.events.length - 1;
    let msg = row.events[index]?.message;
    if (msg && msg.startsWith('El proceso ha concluido correctamente sin errores')) {
      msg = 'Alta registrada correctamente';
      row.events[index].message = msg;
    }
    return row;
  }
  /* #endregion */

  readDate(value: string): Date | null {
    if (value.startsWith('0001')) {
      return null;
    }
    return new Date(value);
  }

  /* #region Actions */
  contactClick(row: IRequestShortDto): void {
    this.teladocSrv.getRequestContactInfo(row.requestId).subscribe({
      next: (result) => {
        const dialogRef: MatDialogRef<ReadableInfoDialogComponent,IRequestClientDataDialogResult> =
          this.dialog.open(ReadableInfoDialogComponent, {
            width: 'auto',
            data: Object.assign({}, result)
          });
        dialogRef.afterClosed().subscribe();
      }
    });
  }

  actionDelete(row: IRequestShortDto): void {
    this.elementLoading = row.requestId;
    const options = this.defaultAskYesNoOptions;
    options.title = 'Esta acción no se podrá deshacer';
    options.subTitle = '¿Segur@ de eliminar esta petición?';
    options.disableClose = false;
    this.actionSrv.open(options).subscribe({
      next: (result: IDialogResult<boolean>) => {
        if (result) {
          if (result.result === DialogResultTypes.Yes) {
            this.resolveDeleteAction(row);
          } else {
            this.elementLoading = -1;
          }
        } else {
          this.elementLoading = -1;
        }
      }
    });
  }

  actionEditData(row: IRequestShortDto): void {
    this.originalRow = Object.assign({}, row);
    const dialogRef: MatDialogRef<RequestClientDataEditDialogComponent,IRequestClientDataDialogResult> =
      this.dialog.open(RequestClientDataEditDialogComponent, {
        disableClose: true,
        width: 'auto',
        data: {
          request: Object.assign({}, row),
          isReactivation: false
        } as IRequestClientDataDialogResult
      });
    dialogRef.afterClosed().subscribe({
      next: (resultEdit) => {
        this.endEditData(row, resultEdit);
      }
    });
  }

  actionForceEditData(row: IRequestShortDto): void {
    this.originalRow = Object.assign({}, row);
    const dialogRef: MatDialogRef<RequestClientDataEditDialogComponent, IRequestClientDataDialogResult> =
      this.dialog.open(RequestClientDataEditDialogComponent, {
        disableClose: true,
        width: 'auto',
        data: {
          request: Object.assign({}, row),
          isReactivation: true
        } as IRequestClientDataDialogResult
      });
    dialogRef.afterClosed().subscribe({
      next: (resultEdit) => {
        this.endEditData(row, resultEdit);
      }
    });
  }

  /* #region Resolving Actions */
  private endEditData(row: IRequestShortDto,resultEdit: IRequestClientDataDialogResult | undefined): void {
    if (resultEdit && resultEdit.ok) {
      const options = this.defaultAskYesNoOptions;
      options.title = '¿Aceptar estos cambios?';
      options.subTitle = 'se enviarán los nuevos al proveedor';
      options.disableClose = false;
      this.actionSrv.open(options).subscribe({
        next: (result: IDialogResult<boolean>) => {
          if (result && result.result === DialogResultTypes.Yes) {
            this.elementLoading = row.requestId;
            this.resolveEditingData(resultEdit.request);
          }
        }
      });
    }
  }

  private resolveEditingData(request: IRequestShortDto): void {
    this.teladocSrv.resolveIncident(request).subscribe({
      next: (requestNew) => {
        const ind = this.dataSource.data.findIndex(
          (r) => r.requestId === request.requestId
        );
        this.dataSource.data[ind] = Object.assign({}, requestNew);
        this.refresh();
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      error: (err) => {
        this.alertSrv.error('Se ha producido un error', '', true, true);
        this.elementLoading = -1;
        this.resetRow();
      },
      complete: () => {
        this.elementLoading = -1;
      }
    });
  }

  private resolveDeleteAction(request: IRequestShortDto): void {
    this.teladocSrv.cancelRequestById(request.requestId).subscribe({
      next: (requestNew) => {
        const ind = this.dataSource.data.findIndex(
          (r) => r.requestId === request.requestId
        );
        this.dataSource.data[ind] = Object.assign({}, requestNew);
        this.refresh();
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      error: (err) => {
        this.alertSrv.error('Se ha producido un error', '', true, true);
        this.elementLoading = -1;
        this.resetRow();
      },
      complete: () => {
        this.elementLoading = -1;
      },
    });
  }

  private resetRow(): void {
    try {
      if (!this.originalRow) {
        return;
      }
      const ind = this.dataSource.data.findIndex(
        (r) => r.requestId === this.originalRow.requestId
      );
      this.dataSource.data[ind] = Object.assign({}, this.originalRow);
      this.originalRow = {} as IRequestShortDto;
      this.refresh();
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const a = 0;
    }
  }
  /* #endregion */

  /* #endregion */

  /* #region States (Show Icons) */
  rowDone(row: IRequestShortDto): boolean {
    return row.done;
  }

  seeDeleteAction(row: IRequestShortDto): boolean {
    return row.done || row.deleted ? false : true;
  }

  rowDelete(row: IRequestShortDto): boolean {
    return row.deleted;
  }

  rowWaiting(row: IRequestShortDto): boolean {
    const result =
      !row.done &&
      (row.state === State.En_Circuito || row.state === State.LOPD_En_Curso);
    return result;
  }

  rowHasAction(row: IRequestShortDto): boolean {
    const result =
      !row.done &&
      row.retries <= 3 &&
      (row.state === State.Fallo_Proveedor ||
        row.state === State.Datos_Erroneos ||
        row.state === State.Esperando_Alta ||
        row.state === State.Incidencia_Contactar_Cliente ||
        row.state === State.Incidencia_Modificar_Datos_Cliente ||
        row.state === State.LOPD_Incidencia ||
        row.state === State.LODP_No_Localizado);
    return result;
  }

  rowHasActionEdit(row: IRequestShortDto): boolean {
    const result =
      !row.done &&
      row.retries < 3 &&
      (row.state === State.Datos_Erroneos ||
        row.state === State.Incidencia_Modificar_Datos_Cliente ||
        row.resultCode === ResultsCodeSubCode.Cliente_No_Localizable);
    return result;
  }
  /* #endregion */
}
