import { KeyValue } from '@angular/common';
import { TimeSlot } from '../enums';

export const TIME_SLOTS_VALUES: KeyValue<TimeSlot, string>[] = [
  {
    key: TimeSlot.Indiferent,
    value: 'Indiferente'
  },
  {
    key: TimeSlot.Mornings,
    value: 'Mañanas'
  },
  {
    key: TimeSlot.Afternoon,
    value: 'Tardes'
  }
] as KeyValue<TimeSlot, string>[];
