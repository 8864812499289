export enum TelehealthLanguage {
  Spanish = 1,
  English = 2,
  German = 3,
  French = 4,
  Portuguese = 5,
  Italian = 6,
  Chinese = 7,
  Catalan = 8
}
