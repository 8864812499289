<h1 mat-dialog-title class="title-text">Datos del cliente</h1>
<div mat-dialog-content class="flex f-fd--column f-gap--20px f-jc--start">
  @if (!model.isReactivation) {
    <div class="flex f-gap--10px margin-bottom-20">
      <howden-label-value class="helpMsg" value="Si no se realiza ningún cambio, no se podrá aceptar"></howden-label-value>
    </div>
  }
  <form [formGroup]="model.form">
    <div class="flex f-fd--column f-gap--20px f-jc--start">
      <div class="flex f-gap--10px">
        <howden-label-value class="sectionTitle" value="Datos generales"></howden-label-value>
      </div>
      <div class="flex f-gap--10px">
        <mat-form-field class="f-basis--3">
          <mat-label>Nombre</mat-label>
          <mat-hint class="matHintSmall">No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="Nombre" [formControlName]="model.C_NAME" [howdenControlError]="nameError"/>
          <mat-error #nameError></mat-error>
          @if (model.form.get(model.C_NAME)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_NAME)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>Apellido 1</mat-label>
          <mat-hint class="matHintSmall">No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="Apellido 1" [formControlName]="model.C_SURNAME1" [howdenControlError]="surname1Error"/>
          <mat-error #surname1Error></mat-error>
          @if (model.form.get(model.C_SURNAME1)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_SURNAME1)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
         <mat-form-field class="f-basis--3">
          <mat-label>Apellido 2</mat-label>
          <mat-hint class="matHintSmall">No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="Apellido 2" [formControlName]="model.C_SURNAME2" [howdenControlError]="surname2Error"/>
          <mat-error #surname2Error></mat-error>
          @if (model.form.get(model.C_SURNAME2)?.hasError('minlength')) {
            <mat-error>3 al menos</mat-error>
          }
          @if (model.form.get(model.C_SURNAME2)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="f-basis--3">
          <mat-label>NIF</mat-label>
          <mat-hint class="matHintSmall">No dejes espacios al principio o al final</mat-hint>
          <input matInput placeholder="NIF" [formControlName]="model.C_NIF" [howdenControlError]="cifError"/>
          <mat-error #cifError></mat-error>
          @if (model.form.get(model.C_NIF)?.hasError('noSpacesEndStart')) {
            <mat-error>Sin espacios, por favor</mat-error>
          }
        </mat-form-field>
        <!-- #endregion -->
      </div>
      <div class="flex f-gap--10px">
        <howden-label-value class="sectionTitle" value="Teléfonos"></howden-label-value>
      </div>
      <div class="flex f-gap--10px">
       <!-- #region Fijo -->
        <mat-form-field class="f-basis--3">
          <mat-label>Fijo</mat-label>
          <input matInput placeholder="Fijo" [formControlName]="model.C_LANDLINE" />
        </mat-form-field>
        <!-- #endregion -->

        <!-- #region Móvil -->
        <mat-form-field class="f-basis--3">
          <mat-label>Móvil</mat-label>
          <input matInput placeholder="Móvil" [formControlName]="model.C_MOBILE" />
        </mat-form-field>
        <!-- #endregion -->

        <div class="f-basis--6 margin-top-20">
          @if (!numbersOK) {
            <mat-error> Uno de los dos debe cubrirse </mat-error>
          }
        </div>
      </div>

      <div class="flex f-gap--10px">
        <howden-label-value class="sectionTitle" value="Información Secundaria"></howden-label-value>
      </div>
      <div class="flex f-gap--10px">
          <mat-form-field class="f-basis--3">
            <mat-label>Fecha Nacimiento</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              [howdenControlError]="fechaError"
              placeholder="nacimiento"
              [formControlName]="model.C_BIRTH"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error #fechaError></mat-error>
          </mat-form-field>

          <mat-form-field class="f-basis--3">
            <mat-label>Idioma</mat-label>
            <mat-select
              [formControlName]="model.C_LANGUAGE"
              [howdenControlError]="languageError"
              >
              <mat-option></mat-option>
              @for (item of model.telehealthLanguages | howdenArraySort : 'value'; track item.key) {
                <mat-option [value]="item.key">
                  {{ item.value }}
                </mat-option>
              }
            </mat-select>
            <mat-error #languageError></mat-error>
          </mat-form-field>
          <!-- #endregion -->

          <!-- #region Horario -->
          <mat-form-field class="f-basis--3">
            <mat-label>Horario</mat-label>
            <mat-select
              [formControlName]="model.C_TIMESLOT"
              [howdenControlError]="timeSlotError"
              >
              <mat-option></mat-option>
              @for (item of model.timeSlotValues | howdenArraySort : 'value'; track item.key) {
                <mat-option [value]="item.key">
                  {{ item.value }}
                </mat-option>
              }
            </mat-select>
            <mat-error #timeSlotError></mat-error>
          </mat-form-field>
          <!-- #endregion -->

          <!-- #region Sex -->
          <mat-form-field class="f-basis--3">
            <mat-label>Sexo</mat-label>
            <mat-select
              [formControlName]="model.C_SEX"
              [howdenControlError]="sexError"
              >
              <mat-option></mat-option>
              @for (item of model.sexValues | howdenArraySort : 'value'; track item.key) {
                <mat-option [value]="item.key">
                  {{ item.value }}
                </mat-option>
              }
            </mat-select>
            <mat-error #sexError></mat-error>
          </mat-form-field>
          <!-- #endregion -->
        </div>

        <div class="flex f-gap--10px margin-top-10">
          <!-- #region Observaciones -->
          <mat-form-field class="f-basis--12">
            <mat-label>Observaciones</mat-label>
            <input matInput placeholder="Observaciones" [formControlName]="model.C_OBSERVATIONS" />
            @if (model.form.get(model.C_OBSERVATIONS)?.hasError('noSpacesEndStart')) {
              <mat-error>Sin espacios, por favor</mat-error>
            }
          </mat-form-field>
          <!-- #endregion -->
        </div>
    </div>
    <div class="flex f-gap--10px">
      <div class="f-basis--6">
        <howden-label-value class="helpMsg" value="En cuanto se acepte, se enviará la actualización al servicio"></howden-label-value>
      </div>
    </div>

    <div mat-dialog-actions [align]="'end'">
      <button mat-stroked-button color="warn" (click)="close(false)">
        <mat-icon class="red">cancel</mat-icon>
        Cancelar
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="(!model.formChanged && !model.isReactivation) || !model.form.valid"
        (click)="close(true)"
      >
        <mat-icon class="blue">task_alt</mat-icon>
        Aceptar
      </button>
      <button mat-raised-button (click)="clean()">
        <mat-icon>cleaning_services</mat-icon>
        <span>Limpiar</span>
      </button>
    </div>
  </form>
</div>
