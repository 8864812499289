import { KeyValue } from '@angular/common';
import { TelehealthLanguage } from '../enums/telehealth-language';

export const TELEHEALTH_LANGUAGES_VALUES: KeyValue<TelehealthLanguage, string>[] = [
  {
    key: TelehealthLanguage.Spanish,
    value: 'Español'
  },
  {
    key: TelehealthLanguage.English,
    value: 'Inglés'
  },
  {
    key: TelehealthLanguage.German,
    value: 'Alemán'
  },
  {
    key: TelehealthLanguage.French,
    value: 'Francés'
  },
  {
    key: TelehealthLanguage.Portuguese,
    value: 'Portugués'
  },
  {
    key: TelehealthLanguage.Italian,
    value: 'Italiano'
  },
  {
    key: TelehealthLanguage.Chinese,
    value: 'Chino'
  },
  {
    key: TelehealthLanguage.Catalan,
    value: 'Catalán'
  }
] as KeyValue<TelehealthLanguage, string>[];
