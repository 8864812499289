import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IManualRequestEditDialogResult } from '@app/shared/models/custom/manual-request-edit-dialog-result';
import { RequestViewModel } from '@features/teladoc-events/models/ui/request-view-model';
import {
  AskForChecksService,
  DialogResultTypes,
  HowdenAlertService,
  IAskForChecksData,
  IDialogResult,
  IHowdenItem
} from '@howdeniberia/core-front';
import { RequestMapper } from '../../mappers/request-mapper';
import { ICompaniesCapitalsDto } from '../../models/ui/companies-capitals-dto';
import { EditManualRequestViewModel } from '../../models/ui/edit-manual-request-view-model';
import { CompanyRequest } from './../../../../shared/enums/common-enum';

@Component({
  selector: 'howden-manual-request-edit-dialog',
  templateUrl: './manual-request-edit-dialog.component.html',
  styleUrls: ['./manual-request-edit-dialog.component.scss']
})
export class ManualRequestEditDialogComponent implements OnInit {
   model: EditManualRequestViewModel = new EditManualRequestViewModel();
  showAdvice = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _companiesInfoRaw: ICompaniesCapitalsDto;

  constructor(
    public dialogRef: MatDialogRef<ManualRequestEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: IManualRequestEditDialogResult,
    private askForChecksSrv: AskForChecksService,
    private warningSrv: HowdenAlertService
  ) {
  }

  ngOnInit(): void {
    this.model.loadFromModel(this.data.value);
    this.loadCompaniesRaw(this.data.value);
    this.showAdvice = this.data.value.advice;
    if (this.showAdvice) {
      this.warningSrv.warning('Atención, pregunta única', 'Alguna de las compañías dependen de la pregunta única de la solicitud, revise las solicitudes', true);
    }
  }

  private loadCompaniesRaw(data: RequestViewModel): void {
    const valCompany = data.companies.split(';');
    const valCapitals = data.capitals.split(';');

    this._companiesInfoRaw = {
      capitals: [],
      companies: []
    } as ICompaniesCapitalsDto;

    for (const [key, value] of Object.entries(valCapitals)) {
      this._companiesInfoRaw.capitals.push(value);
      this._companiesInfoRaw.companies.push(valCompany[+key]);
    }
  }

  public get numbersOK(): boolean {
    const land = this.model.form.get(this.model.C_LANDLINE)?.value;
    const mob = this.model.form.get(this.model.C_MOBILE)?.value;
    const res = new RequestMapper().validateContactNumbers(land?.toString(), mob?.toString());
    return res;
  }

  public get capitalsKO(): boolean {
    const res = this.model.capitals.includes('X');
    return res;
  }

  /* #region Select Options */
  selectCompanies(): void {
    const options = {
      title: 'Seleccione Cía',
      subTitle: 'Pueden ser más de una',
      label: 'Compañías',
      allowCancel: true,
      items: this.getCompaniesOptions(),
      initialValue: this.getCompaniesSelected()
    } as IAskForChecksData;

    this.askForChecksSrv.open(options).subscribe((result: IDialogResult<IHowdenItem[]>) => {
      const currentVal = this.model.companies;
      if (result.result === DialogResultTypes.OK) {
        const val = result.resultData?.length === 0 ? '' : result.resultData?.map(x => x.description).join(';');
        this.model.companies = val ? val : currentVal;
        // update capitals
        this.model.capitals = this.reWriteCapitals();
        if (this.capitalsKO) {
          this.model.form.get(this.model.C_CAPITALS)?.setErrors({ 'wrongValue': true });
        }
      }
    });
  }

  private reWriteCapitals(): string {
    const companiesSelected = this.model.companies.split(';');
    const result: Array<string> = [];
    companiesSelected.forEach(company => {
      const index = this._companiesInfoRaw.companies.indexOf(company);
      result.push(index > -1 ? `${this._companiesInfoRaw.capitals[index]}` : 'XXX');
    });
    return result.join(';');
  }

  private getCompaniesOptions(): IHowdenItem[] {
    const result: IHowdenItem[] = [];
    try {
      for (const key in CompanyRequest) {
        if (!isNaN(Number(key))) {
          result.push({ key: Number(key), description: CompanyRequest[key] } as IHowdenItem);
        }
      }
    } catch (ex) {
      // Error ignored
    }
    return result;
  }

  private getCompaniesSelected(): number[] {
    const result: number[] = [];
    const companiesValue = this.model.companies;
    if (companiesValue === '') {
      return result;
    }
    try {
      for (const key in CompanyRequest) {
        if (isNaN(Number(key))) {
          const companyValue = CompanyRequest[key];
          if (companiesValue.includes(key)) {
            result.push(+companyValue);
          }
        }
      }
    } catch (ex) {
      // Error ignored
    }
    return result;
  }

    /* #endregion */

  clean(): void {
    this.model.form.reset();
  }

  close(result: boolean): void {
    if (result && this.model.form.invalid) {
      return;
    }
    this.data.ok = result;
    this.cleanResult();
    this.data.value = new RequestMapper().editManualRequestViewModelToRequestViewModel(this.model);
    this.dialogRef.close(this.data);
  }

  cleanResult(): void {
    if (this.model.nif !== null) {
      this.model.nif = this.model.nif.trim().split('-').join('').split(' ').join('').split('(').join('').split(')').join('');
    }
    if (this.model.landLine !== null) {
      this.model.landLine = this.model.landLine.trim().split('-').join('').split(' ').join('').split('(').join('').split(')').join('');
    }
    if (this.model.mobilePhone !== null) {
      this.model.mobilePhone = this.model.mobilePhone.trim().split('-').join('').split(' ').join('').split('(').join('').split(')').join('');
    }
  }
}
